import { Content } from "./Content"
import Box from "./Box";
import { FC } from 'react';

interface Props {
  children: any
}

export const Layout: FC<Props> = ({ children }) => (
  <Box
    css={{
      maxW: "100%"
    }}
  >
    {children}
    <Content />
  </Box>
);

export {};