import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Navbar, Button, Link, Text, Card, Radio } from "@nextui-org/react";
import { Layout } from "./Layout";
import { NextUIProvider, createTheme } from '@nextui-org/react';

function App() {
  const theme = createTheme({
    type: "dark", // it could be "light" or "dark"
    theme: {
      colors: {
        // brand colors
        primaryLight: '$green200',
        primaryLightHover: '$green300',
        primaryLightActive: '$green400',
        primaryLightContrast: '$green600',
        primary: '#4ADE7B',
        primaryBorder: '$green500',
        primaryBorderHover: '$green600',
        primarySolidHover: '$green700',
        primarySolidContrast: '$white',
        primaryShadow: '$green500',
  
        gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
        link: '#5E1DAD',
  
        // you can also create your own color
        myColor: '#ff4ecd'
  
        // ...  more colors
      },
      space: {},
      fonts: {}
    }
  })  

  return (
    <NextUIProvider theme={theme}>
      <Layout>
          <Navbar isBordered={true} variant={"sticky"}>
            <Navbar.Brand>
              <Text b hideIn="xs">
                ACME
              </Text>
            </Navbar.Brand>
            <Navbar.Content hideIn="xs">
              <Navbar.Link href="#">Features</Navbar.Link>
              <Navbar.Link isActive href="#">Customers</Navbar.Link>
              <Navbar.Link href="#">Pricing</Navbar.Link>
              <Navbar.Link href="#">Company</Navbar.Link>
            </Navbar.Content>
            <Navbar.Content>
              <Navbar.Link color="inherit" href="#">
                Login
              </Navbar.Link>
              <Navbar.Item>
                <Button auto flat as={Link} href="#">
                  Sign Up
                </Button>
              </Navbar.Item>
            </Navbar.Content>
          </Navbar> 
      </Layout>
    </NextUIProvider>
  );
}

export default App;
